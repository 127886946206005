import React, {useEffect} from 'react';
import {navigate} from "gatsby";

const Checkout = () => {
    useEffect(() => {
       navigate('/');
    }, []);

    return (<div></div>);
};
export default Checkout;
